// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-about-us-our-company-js": () => import("./../../../src/pages/about-us/our-company.js" /* webpackChunkName: "component---src-pages-about-us-our-company-js" */),
  "component---src-pages-balance-transfer-faq-jsx": () => import("./../../../src/pages/balance-transfer-faq.jsx" /* webpackChunkName: "component---src-pages-balance-transfer-faq-jsx" */),
  "component---src-pages-brokers-js": () => import("./../../../src/pages/brokers.js" /* webpackChunkName: "component---src-pages-brokers-js" */),
  "component---src-pages-calculators-and-tools-js": () => import("./../../../src/pages/calculators-and-tools.js" /* webpackChunkName: "component---src-pages-calculators-and-tools-js" */),
  "component---src-pages-complaints-index-js": () => import("./../../../src/pages/complaints/index.js" /* webpackChunkName: "component---src-pages-complaints-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-credit-card-change-faq-jsx": () => import("./../../../src/pages/credit-card-change-faq.jsx" /* webpackChunkName: "component---src-pages-credit-card-change-faq-jsx" */),
  "component---src-pages-credit-cards-become-a-partner-js": () => import("./../../../src/pages/credit-cards/become-a-partner.js" /* webpackChunkName: "component---src-pages-credit-cards-become-a-partner-js" */),
  "component---src-pages-credit-cards-gem-creditline-card-js": () => import("./../../../src/pages/credit-cards/gem-creditline-card.js" /* webpackChunkName: "component---src-pages-credit-cards-gem-creditline-card-js" */),
  "component---src-pages-credit-cards-gem-essential-js": () => import("./../../../src/pages/credit-cards/gem-essential.js" /* webpackChunkName: "component---src-pages-credit-cards-gem-essential-js" */),
  "component---src-pages-credit-cards-gem-visa-card-acquired-at-apple-js": () => import("./../../../src/pages/credit-cards/gem-visa-card/acquired-at-apple.js" /* webpackChunkName: "component---src-pages-credit-cards-gem-visa-card-acquired-at-apple-js" */),
  "component---src-pages-credit-cards-gem-visa-card-balance-transfer-jsx": () => import("./../../../src/pages/credit-cards/gem-visa-card/balance-transfer.jsx" /* webpackChunkName: "component---src-pages-credit-cards-gem-visa-card-balance-transfer-jsx" */),
  "component---src-pages-credit-cards-gem-visa-card-balance-transfer-offer-jsx": () => import("./../../../src/pages/credit-cards/gem-visa-card/balance-transfer-offer.jsx" /* webpackChunkName: "component---src-pages-credit-cards-gem-visa-card-balance-transfer-offer-jsx" */),
  "component---src-pages-credit-cards-gem-visa-card-balance-transfer-offer-v-2-jsx": () => import("./../../../src/pages/credit-cards/gem-visa-card/balance-transfer-offer-v2.jsx" /* webpackChunkName: "component---src-pages-credit-cards-gem-visa-card-balance-transfer-offer-v-2-jsx" */),
  "component---src-pages-credit-cards-gem-visa-card-campaign-spend-and-get-js": () => import("./../../../src/pages/credit-cards/gem-visa-card/campaign/spend-and-get.js" /* webpackChunkName: "component---src-pages-credit-cards-gem-visa-card-campaign-spend-and-get-js" */),
  "component---src-pages-credit-cards-gem-visa-card-harvey-norman-index-jsx": () => import("./../../../src/pages/credit-cards/gem-visa-card/harvey-norman/index.jsx" /* webpackChunkName: "component---src-pages-credit-cards-gem-visa-card-harvey-norman-index-jsx" */),
  "component---src-pages-credit-cards-gem-visa-card-index-jsx": () => import("./../../../src/pages/credit-cards/gem-visa-card/index.jsx" /* webpackChunkName: "component---src-pages-credit-cards-gem-visa-card-index-jsx" */),
  "component---src-pages-credit-cards-interest-free-offers-js": () => import("./../../../src/pages/credit-cards/interest-free-offers.js" /* webpackChunkName: "component---src-pages-credit-cards-interest-free-offers-js" */),
  "component---src-pages-credit-cards-terms-and-conditions-js": () => import("./../../../src/pages/credit-cards/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-credit-cards-terms-and-conditions-js" */),
  "component---src-pages-credit-cards-where-to-shop-js": () => import("./../../../src/pages/credit-cards/where-to-shop.js" /* webpackChunkName: "component---src-pages-credit-cards-where-to-shop-js" */),
  "component---src-pages-cyber-response-jsx": () => import("./../../../src/pages/cyber-response.jsx" /* webpackChunkName: "component---src-pages-cyber-response-jsx" */),
  "component---src-pages-digital-wallets-apple-pay-js": () => import("./../../../src/pages/digital-wallets/apple-pay.js" /* webpackChunkName: "component---src-pages-digital-wallets-apple-pay-js" */),
  "component---src-pages-digital-wallets-garmin-pay-js": () => import("./../../../src/pages/digital-wallets/garmin-pay.js" /* webpackChunkName: "component---src-pages-digital-wallets-garmin-pay-js" */),
  "component---src-pages-digital-wallets-google-pay-js": () => import("./../../../src/pages/digital-wallets/google-pay.js" /* webpackChunkName: "component---src-pages-digital-wallets-google-pay-js" */),
  "component---src-pages-disclosures-index-tsx": () => import("./../../../src/pages/disclosures/index.tsx" /* webpackChunkName: "component---src-pages-disclosures-index-tsx" */),
  "component---src-pages-eappserror-js": () => import("./../../../src/pages/eappserror.js" /* webpackChunkName: "component---src-pages-eappserror-js" */),
  "component---src-pages-eappserror-ocv-js": () => import("./../../../src/pages/eappserror-ocv.js" /* webpackChunkName: "component---src-pages-eappserror-ocv-js" */),
  "component---src-pages-forms-index-js": () => import("./../../../src/pages/forms/index.js" /* webpackChunkName: "component---src-pages-forms-index-js" */),
  "component---src-pages-gem-visa-info-js": () => import("./../../../src/pages/gem-visa-info.js" /* webpackChunkName: "component---src-pages-gem-visa-info-js" */),
  "component---src-pages-gem-visa-offers-js": () => import("./../../../src/pages/gem-visa-offers.js" /* webpackChunkName: "component---src-pages-gem-visa-offers-js" */),
  "component---src-pages-genoapay-closure-js": () => import("./../../../src/pages/genoapay/closure.js" /* webpackChunkName: "component---src-pages-genoapay-closure-js" */),
  "component---src-pages-genoapay-index-js": () => import("./../../../src/pages/genoapay/index.js" /* webpackChunkName: "component---src-pages-genoapay-index-js" */),
  "component---src-pages-hardship-care-index-js": () => import("./../../../src/pages/hardship-care/index.js" /* webpackChunkName: "component---src-pages-hardship-care-index-js" */),
  "component---src-pages-help-centre-terms-of-use-js": () => import("./../../../src/pages/help-centre/terms-of-use.js" /* webpackChunkName: "component---src-pages-help-centre-terms-of-use-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-index-js": () => import("./../../../src/pages/insurance/index.js" /* webpackChunkName: "component---src-pages-insurance-index-js" */),
  "component---src-pages-insurance-make-a-claim-js": () => import("./../../../src/pages/insurance/make-a-claim.js" /* webpackChunkName: "component---src-pages-insurance-make-a-claim-js" */),
  "component---src-pages-loans-apply-js": () => import("./../../../src/pages/loans/apply.js" /* webpackChunkName: "component---src-pages-loans-apply-js" */),
  "component---src-pages-loans-budget-planner-js": () => import("./../../../src/pages/loans/budget-planner.js" /* webpackChunkName: "component---src-pages-loans-budget-planner-js" */),
  "component---src-pages-loans-car-loan-repayment-calculator-js": () => import("./../../../src/pages/loans/car-loan-repayment-calculator.js" /* webpackChunkName: "component---src-pages-loans-car-loan-repayment-calculator-js" */),
  "component---src-pages-loans-car-loans-js": () => import("./../../../src/pages/loans/car-loans.js" /* webpackChunkName: "component---src-pages-loans-car-loans-js" */),
  "component---src-pages-loans-debt-consolidation-loans-js": () => import("./../../../src/pages/loans/debt-consolidation-loans.js" /* webpackChunkName: "component---src-pages-loans-debt-consolidation-loans-js" */),
  "component---src-pages-loans-home-renovation-loans-js": () => import("./../../../src/pages/loans/home-renovation-loans.js" /* webpackChunkName: "component---src-pages-loans-home-renovation-loans-js" */),
  "component---src-pages-loans-js": () => import("./../../../src/pages/loans.js" /* webpackChunkName: "component---src-pages-loans-js" */),
  "component---src-pages-loans-loan-repayment-calculator-js": () => import("./../../../src/pages/loans/loan-repayment-calculator.js" /* webpackChunkName: "component---src-pages-loans-loan-repayment-calculator-js" */),
  "component---src-pages-loans-personal-loans-js": () => import("./../../../src/pages/loans/personal-loans.js" /* webpackChunkName: "component---src-pages-loans-personal-loans-js" */),
  "component---src-pages-loans-travel-loans-js": () => import("./../../../src/pages/loans/travel-loans.js" /* webpackChunkName: "component---src-pages-loans-travel-loans-js" */),
  "component---src-pages-loans-wedding-loans-js": () => import("./../../../src/pages/loans/wedding-loans.js" /* webpackChunkName: "component---src-pages-loans-wedding-loans-js" */),
  "component---src-pages-mobile-app-js": () => import("./../../../src/pages/mobile-app.js" /* webpackChunkName: "component---src-pages-mobile-app-js" */),
  "component---src-pages-partner-discount-js": () => import("./../../../src/pages/partner-discount.js" /* webpackChunkName: "component---src-pages-partner-discount-js" */),
  "component---src-pages-pay-it-faster-js": () => import("./../../../src/pages/pay-it-faster.js" /* webpackChunkName: "component---src-pages-pay-it-faster-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-repayment-calculator-index-js": () => import("./../../../src/pages/repayment-calculator/index.js" /* webpackChunkName: "component---src-pages-repayment-calculator-index-js" */),
  "component---src-pages-scams-hub-index-js": () => import("./../../../src/pages/scams-hub/index.js" /* webpackChunkName: "component---src-pages-scams-hub-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-security-how-to-protect-yourself-js": () => import("./../../../src/pages/security/how-to-protect-yourself.js" /* webpackChunkName: "component---src-pages-security-how-to-protect-yourself-js" */),
  "component---src-pages-security-how-we-protect-you-js": () => import("./../../../src/pages/security/how-we-protect-you.js" /* webpackChunkName: "component---src-pages-security-how-we-protect-you-js" */),
  "component---src-pages-security-index-js": () => import("./../../../src/pages/security/index.js" /* webpackChunkName: "component---src-pages-security-index-js" */),
  "component---src-pages-security-responsible-disclosure-js": () => import("./../../../src/pages/security/responsible-disclosure.js" /* webpackChunkName: "component---src-pages-security-responsible-disclosure-js" */),
  "component---src-pages-supporting-documents-index-js": () => import("./../../../src/pages/supporting-documents/index.js" /* webpackChunkName: "component---src-pages-supporting-documents-index-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-upload-error-js": () => import("./../../../src/pages/upload/error.js" /* webpackChunkName: "component---src-pages-upload-error-js" */),
  "component---src-pages-upload-index-js": () => import("./../../../src/pages/upload/index.js" /* webpackChunkName: "component---src-pages-upload-index-js" */),
  "component---src-pages-vulnerable-customers-jsx": () => import("./../../../src/pages/vulnerable-customers.jsx" /* webpackChunkName: "component---src-pages-vulnerable-customers-jsx" */),
  "component---src-templates-scams-hub-article-template-js": () => import("./../../../src/templates/scams-hub/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-scams-hub-article-template-js" */)
}

